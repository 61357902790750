import { KpButton } from "@elements/index"
import { Address, Checkout } from "@models/Checkout/Checkout"
import { getCurrentCheckout } from "@redux/localStorage/checkout"
import { currentCheckoutSelector } from "@redux/selectors/checkoutSelector"
import { DealerService } from "@services/DealerService"
import { AuthFormLabel, AuthTitle } from "@styles/Global.forms"
import theme from "@styles/theme"
import { ButtonTypes, PaymentOptions } from "@utils/Constant"
import { graphql, Link, navigate, StaticQuery } from "gatsby"
import React from "react"
import { connect } from "react-redux"
import { Form, Input } from "semantic-ui-react"
import ErrorMessage from "../../auth/ErrorMessage"
import { AuthPageWrapper, SignUpWrapper } from "../../auth/index.styles"
import { validateDealerSignInForm } from "../../auth/Validator"
import { DealerSignInLink } from "./OwnerDetailsForm.style"
import { Record } from 'immutable';
import { Dispatch } from "redux"
import { updatePersonalDetails } from "@redux/actions"
import { updateDealerAddress, updateIsDealerOrder, updatePaymentType } from "@redux/actions/checkout"

const DealerSignIn = () => {
    return (
        <StaticQuery
            query={graphql`
                {
                site {
                    siteMetadata {
                    apiUrl
                    }
                }
                }
            `}
            render={data => {
                const apiUrl = data.site.siteMetadata.apiUrl
                return <AuthPageWrapper>
                    <SignUpWrapper>
                        <AuthTitle>Dealer Login <span style={{'fontSize': '12px', 'color': 'blue', 'cursor': 'pointer'}} onClick={() => navigate("/dealer-login")}>Cognito</span></AuthTitle>
                        <DealerSignInForm apiUrl={apiUrl} />
                        <DealerSignInLink>
                            <Link to="/forgot-salespersonId">Forgot Salesperson Id?</Link>
                        </DealerSignInLink>
                        <DealerSignInLink><a href="mailto:dealers@kiwiplates.nz">Request An Account</a></DealerSignInLink>
                    </SignUpWrapper>
                </AuthPageWrapper>
            }}
        />

    )
}

export default DealerSignIn

interface Props {
    apiUrl: string
    updatePersonalDetails: (personalDetails: Checkout) => void
    updateDealerAddress: (dealerAddress: Address) => void
    updateIsDealerOrder: (isDealerOrder: boolean) => void
    updatePaymentType: (paymentType: number) => void
}

interface States {
    account: {
        email: string,
        userId: string
    },
    loading: boolean,
    error: string
}

class DealerSignInFormContainer extends React.Component<Props, States>{
    constructor(props: Props) {
        super(props)
        this.state = {
            account: {
                email: "",
                userId: ""
            },
            error: "",
            loading: false
        }
    }

    handleSignIn = (e: any) => {
        e.preventDefault()
        if (validateDealerSignInForm(this.state.account.email, this.state.account.userId, (error) => { this.setState({ error: error }) })) {
            this.setState({ loading: true })
            DealerService.login(this.props.apiUrl, this.state.account.email, this.state.account.userId)
                .then(response => {
                    if (response.Success && response.Status == 200) {
                        var checkout = getCurrentCheckout()
                        checkout.isDealerOrder = true
                        checkout.dealerDetails = {
                            dealerId: response.Data.DealerId,
                            dealerName: response.Data.DealerName,
                            salesPersonEmail: response.Data.SalesPersonEmail,
                            salesPersonId: response.Data.SalesPersonId,
                            salesPersonName: response.Data.SalesPersonName
                        }
                        checkout.dealerAddress = {
                            fullAddress: response.Data.FullAddress,
                            street: response.Data.Street,
                            building: response.Data.Building,
                            unit: response.Data.Unit,
                            suburb: response.Data.Suburb,
                            city: response.Data.City,
                            postCode: response.Data.PostCode,
                            isManualAddress: true
                        }
                        this.props.updatePaymentType(PaymentOptions.CreditCard)
                        this.props.updatePersonalDetails(checkout);
                        this.props.updateDealerAddress(checkout.dealerAddress);
                        this.props.updateIsDealerOrder(checkout.isDealerOrder);

                        navigate("/dealer-new-order")
                    } else {
                        var errMessage = "Please input correct salesperson Id and email.";
                        if(response.Status == 401){
                            errMessage = response.Message;
                        }
                        this.setState({ error: errMessage })
                        this.setState({ loading: false })
                    }
                }).catch(exception => {
                    this.setState({ error: exception.message })
                    this.setState({ loading: false })
                })
        }
    }

    updateAccount = (account: { email: string, userId: string }) => {
        this.setState({ account: account })
        this.setState({ error: "" })
    }

    render() {
        return (
            <Form onSubmit={this.handleSignIn}>
                <Form.Field>
                    <AuthFormLabel>SALESPERSON ID</AuthFormLabel>
                    <Input type='text' name='userId' id='userId'
                        placeHolder='yourname@email.com'
                        value={this.state.account.userId}
                        onChange={e => this.updateAccount({ email: this.state.account.email, userId: e.target.value })}
                    />
                </Form.Field>
                <Form.Field>
                    <AuthFormLabel>SALESPERSON EMAIL</AuthFormLabel>
                    <input type='text' name='email' id='email' value={this.state.account.email}
                        onChange={e => this.updateAccount({ userId: this.state.account.userId, email: e.target.value })} />
                </Form.Field>
                <Form.Field>
                    <KpButton id='signin'
                        color={theme.brand.colors.green}
                        fullWidth="mobile tablet computer"
                        buttonType={ButtonTypes.Primary}
                        loading={this.state.loading}
                        type="submit"> {this.state.loading ? "Loading..." : "Continue"}</KpButton>
                </Form.Field>
                {this.state.error && <ErrorMessage errorMessage={this.state.error} />}
            </Form>
        )
    }

}

const mapStateToProps = (state: Record<any>) => {
    const checkout = currentCheckoutSelector(state).toJS() as Checkout;
    return {
        checkoutState: checkout,
    };
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    updatePersonalDetails(personalDetails: Checkout) {
        let validatedPersonalDetails = personalDetails;
        validatedPersonalDetails.applicantDetails.email = validatedPersonalDetails.applicantDetails.email.trim();
        validatedPersonalDetails.ownerDetails.email = validatedPersonalDetails.ownerDetails.email.trim();
        dispatch(updatePersonalDetails(validatedPersonalDetails))
    },
    updateDealerAddress(dealerAddress: Address) {
        dispatch(updateDealerAddress(dealerAddress))
    },
    updateIsDealerOrder(isDealerOrder: boolean) {
        dispatch(updateIsDealerOrder(isDealerOrder))
    },
    updatePaymentType(paymentType: number) {
        dispatch(updatePaymentType(paymentType))
    },
})
const DealerSignInForm = connect(mapStateToProps, mapDispatchToProps)(DealerSignInFormContainer)
