import React, { Fragment } from "react"
import { Layout } from "@components/Layout"
import { SEO } from "@components/SEO"
import DealerSignIn from "@components/Dealer/DealerSignIn"
import { getCurrentCheckout } from "@redux/localStorage/checkout"
import { StaticQuery, graphql, navigate } from "gatsby"
import SignIn from "@auth/Signin"

const DealerLoginLegacyPage = () =>{
    const checkout = getCurrentCheckout()
    return (
        <Layout version="dealer">
            <SEO title="Dealer Login" />
            {checkout.isDealerOrder && (checkout.dealerDetails?.dealerId || 0) > 0 ? navigate("/dealer-new-order") : <DealerSignIn />}
        </Layout>
    )
}

export default DealerLoginLegacyPage

