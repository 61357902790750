import React from "react";
import styled from "@emotion/styled";

type ErrorMessageProps = {
  errorMessage: Error | string;
};
const ErrorMessageContainer = styled.p`
  text-align: center;
  margin-top: 10px;
  color: rgba(200, 0, 0, 0.8);
`;
const ErrorMessage: React.FC<ErrorMessageProps> = ({
  errorMessage,
}) => {
  return <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>;
};
export default ErrorMessage;