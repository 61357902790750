import { NameRegex } from "@utils/Constant"
import { parse } from "path"
import * as validator from "validator"
/** Handle form validation for the signup form
 * @param email - user's email
 * @param password - user's chsoen password
 * @param passwordRetype - user's retyped password
 */
export const validateForm = (
  name: string,
  email: string,
  password: string,
  // passwordRetype: string,
  setError: (error: Error) => void
): boolean => {
  if (name.trim().length == 0) {
    const error = { name: "NameIsRequired", message: "Name is required." }
    setError(error)
    return false
  }
  
  if (!NameRegex.test(name.trim())){
    const error = { name: "ValidCharacters", message: "Enter valid characters" }
    setError(error)
    return false
  }
  // Check for invalid email
  if (!validator.default.isEmail(email)) {
    setError({
      name: "InvalidEmail",
      message: "Please enter a valid email address.",
    })
    return false
  }
  // check if passwords match
  // if (password !== passwordRetype) {
  //   setError("The passwords you entered don't match.");
  //   return false;
  // }
  return true
}

/** Handle form validation for the signup form
 * @param email - user's email
 * @param password - user's chsoen password
 */
export const validateSignInForm = (
  email: string,
  password: string,
  setError: (error: string) => void
): boolean => {
  // Check for invalid email
  if (!validator.default.isEmail(email)) {
    setError("Please enter a valid email address.")
    return false
  }
  // check if password field is empty
  if (!password) {
    setError("Please enter a valid password.")
    return false
  }
  return true
}

export const validateDealerSignInForm = (
  email:string,
  salesperonId: string,
  setError: (error:string) => void) : boolean =>{
  if(!validator.default.isEmail(email)){
    setError("Please enter a valid email address.")
    return false
  }

  if (parseInt(salesperonId) <= 0 ){
    setError("Please enter a valid Salesperson Id.")
    return false
  }
  return true
}

export const validateDealerEmail = (
  email:string,
  setError: (error:string) => void) : boolean =>{
  if(!validator.default.isEmail(email)){
    setError("Please enter a valid email address.")
    return false
  }
  return true
}